import { Controller } from "./Controller";

let controller: Controller | null = null;

window.onload = async () => {
  console.log("Hello World!");
  controller = new Controller();
  //Create an ELIZA chatbot

  // Get user input from the DOM
};
